.cart-icon {
    position: fixed;
    bottom: 20px;
    /* 20px from the bottom */
    right: 10%;
    /* 20px from the right */
    background-color: #F5EFEA;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* Ensure it stays on top */
}

.cart-icon img {
    width: 24px;
    height: 24px;
}

.cart-item-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
}

@media (min-width: 1600px) {
    .cart-icon {
        right: auto;
        left: 80% !important;
    }
}

@media (max-width: 1200px) {
    .cart-icon {
        right: 20px;
    }
}