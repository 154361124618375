.refer-earn {
  padding: 0px 48px 0px 48px;
  border-radius: 20px;
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  text-align: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.refer-earn-content {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  gap: 20px;
  width: 100%;
}

.refer-earn-photo {
  margin-bottom: 0px;
}

.refer-earn-photo img {
  width: 100%;
  max-width: 456px;
  border-radius: 50%;
}

.refer-earn-text {
  flex: 1;
  padding: 8px;
  text-align: left;
  margin-top: 0px;
  max-width: 90%;
}

.refer-earn-text h1 {
  margin-bottom: 40px;
  max-width: 72%;
  text-align: left;
}


.refer-earn-text p {
  margin-top: 0px;
  margin-bottom: 40px;
  max-width: 72%;
  text-align: left;
}

.refer-earn-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.refer-earn-form input {
  padding: 16px;
  margin-bottom: 8px;
  width: 72%;
}

#refer-button {
  margin-top: 58px !important;
  max-width: 290px !important;
}

.success-message {
  font-size: 1.5em;
  color: #01172F;
  text-align: left;
  margin-top: 0px;
  padding-top: 12px;
  padding-left: 4px;
}

body.dark .success-message {
  color: #fff;
}

@media (max-width: 1200px) {
  .refer-earn {
    padding: 0px;
    margin-left: 0px;
  }
  .refer-earn-content {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .refer-earn-text {
    text-align: center;
    padding: 10px;
    width: 100%;
  }

  .refer-earn-text h1 {
    max-width: 100% !important;
  }

  .refer-earn-text p {
    max-width: 100% !important;
  }

  .refer-earn-form input {
    width: 100%;
  }

  .refer-earn-form {
    width: 100%;
  }

  #refer-button {
    margin-top: 48px !important;
    margin-bottom: 48px;
    max-width: 220px !important;
  }
}
