.supporting1 {
  background-color: #EFE5DE;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.supporting1.dark {
  background-color: #121212;
}

.steps-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url('../../assets/images/supporting-bg.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 40px;
  color: #001c35;
  width: 100%;
}

.steps-section.dark {
  background-image: url('../../assets/images/supporting-bg-dark.svg');
}

.steps-section p {
  color: #fff;
  max-width: 216px;
  margin-bottom: 86px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.step-icon {
  width: 216px;
  height: 216px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 48px;
}

.supporting-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
  border-radius: 20px;
  margin: 20px 0px;
}

.steps-section h1 {
  color: #001c35;
  margin-bottom: 24px !important;
}

.steps-section.dark h1 {
  color: #fff;
}

.supporting-section h1 {
  color: #001c35;
  text-transform: uppercase;
  margin-top: 176px;
  margin-bottom: 176px;
}

.supporting1.dark .supporting-section h1 {
  color: #fff;
}

h2 {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
}

h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin: 5px 0;
}
.step h3 {
  color: #fff !important;
}

p {
  font-size: 0.9em;
  margin: 0;
}

.steps-section-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1200px;
  margin-left:auto;
  margin-right:auto;
  text-align: center;

}

.step-loading {
  padding-right: 40px;
}

.feature-text-loading {
  margin-top: 216px;
  text-align: center;
}

/* Media Query for screens smaller than 820px */
@media (max-width: 820px) {

  .supporting1 {
    margin-left: 0;
  }

  .steps-section {
    flex-direction: column;
    align-items: center;
    background-image: none !important;
    padding: 0px;
  }

  .steps-section p {
    color: #001c35;
  }
  

  .step {
    margin-bottom: 20px;
  }

  .step h3 {
    color: #001c35 !important;
  }

  .steps-section.dark .step h3 {
    color: #fff !important;
  }

  .supporting-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 0px;
  }

  .supporting-section h1 {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: left !important;
    font-size: 2.2em;
    word-wrap: break-word;
    word-break: normal;
  }

  .steps-section-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width: 100vw;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
  }

  .step-loading {
    padding-right: 0px;
    padding-bottom: 40px;
  }

  
}
