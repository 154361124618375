.coffee-products {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 64px;
  margin-bottom: 216px;
  max-width: 100vw;
}

.buttons {
  margin-bottom: 76px;
  left: 20px;
}

.buttons button {
  background-color: transparent;
  border: 4px solid #001c35;
  color: #001c35;
  padding: 6px 24px;
  margin: 0 8px;
  cursor: pointer;
  border-radius: 32px;
  transition: background-color 0.3s;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Montserrat;
  font-size: 24px;
  height: 64px;
}

.buttons button.active,
.buttons button:hover {
  background-color: #D9D9D9;
}

.buttons.dark button {
  background-color: transparent;
  border: 4px solid #fff;
  color: #fff;
  padding: 6px 24px;
  margin: 0 8px;
  cursor: pointer;
  border-radius: 32px;
  transition: background-color 0.3s;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Montserrat;
  font-size: 24px;
}

.buttons.dark button.active {
  background-color: #414141;
  color: #fff;
  transition: none;
}

.buttons.dark button:hover {
  background-color: #414141;
}

.buttons.dark button.active:hover {
  background-color: #414141;
}

.products {
  display: flex;
  justify-content: left;
  flex-wrap: no-wrap;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling on iOS */
  margin-right: -80px !important;
}

.product {
  background-color: transparent;
  text-align: left;
  width: 452px;
  position: relative;
  padding: 0px 20px 0px 0px;
  margin-right: 80px;
}

.product span:first-child  {
  width: 452px;
  height: 452px;
}

.product .product-img {
  width: 452px;
  height: auto;
  border-radius: 10px;
  margin-bottom: -80px;
}

.price-per-pound {
  background-image: url('../../assets/images/price-bg-light.svg');
  background-size:contain; /* Ensures the image covers the entire element */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image */
  padding: 24px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 50px !important;
  display: inline-block;
  color: #001c35;
}

.price-per-pound.dark {
  background-image: url('../../assets/images/price-bg-dark.svg');
  background-size:contain; /* Ensures the image covers the entire element */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image */
  color: #fff;
}


.product .price {
  margin-bottom: 76px;
}


.note {
  font-size: 0.9em;
  margin-top: 108px;
  padding: 0px;
}

.note a {
  color: #8F8D8D;
  text-decoration: none;
  font-weight: bold;
}

/* Styles for desktop (skeletons displayed inline) */
.buttons .skeleton-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  /* Adds space between skeletons */
}

@media (min-width: 1600px) {
  .coffee-products {
    max-width: 1200px;
  }
}

/* Media Query for screens smaller than 820px */
@media (max-width: 820px) {
  .coffee-products {
    margin-left: 0px;
    max-width: 100vw;
    padding-left:0px !important;
  }

  .buttons button {
    margin-bottom: 12px !important;
  }

  .products {
    margin-right: 0px !important;
    padding: 0px;
    overflow-x: scroll;
    width: 90vw !important; /* Constrain to viewport width */
  }

  .product {
    padding: 24px 0px;
    width: 246px;
    margin-right: 44px;
  }

  .product span:first-child {
    width: 246px;
    height: 246px;
  }

  .product .product-img {
    width: 246px;
    margin-bottom: -40px;
  }

  .coffee-products .shop-title-loading .react-loading-skeleton {
    width: 120px !important;
    height: 40px !important;
  }

  /* Default styles for mobile (skeletons displayed in column) */
  .buttons .skeleton-container {
    display: flex;
    flex-direction:column;
    gap: 10px;
    /* Adds space between skeletons */
  }
    /* Second child of React Loading Skeleton */
    .product span:nth-of-type(2) > :first-child {
      width: 220px !important;
    }
}