header {
  padding: 24px 0px 35px 0px;
  margin: auto;
  max-width: 1200px;
}

.desktop-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  padding: auto;
  margin: 0 auto;
  /* Center the entire .desktop-menu within the header */
  list-style: none;
}

.menu-section ul {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-section ul li {
  padding-right: 24px;
  list-style-type: none;
}

.menu-section ul li a {
  text-decoration: none;
  color: #01172F;
  font-weight: bold;
}

header.dark nav ul li a {
  color: #ffffff;
}

a.active {
  text-decoration: underline !important;
  text-underline-offset: 6px;
}

nav ul li a img {
  max-width: 140px !important;
  min-width: 120px !important;
}

#coffeeLogo {
  max-width: 140px !important;
  margin-right: 100px !important;
}


.loading-item {
  width: auto;
  height: 40px !important;
  margin: 0px 8px;
  padding: 0px;
}

.loading .loading-item {
  background: linear-gradient(90deg, #F9F5F2, #CCD1D5);
  background-size: 200% 200%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

.loading-item:first-child {
  width: 160px !important;
  height: 160px !important;
  margin-right: 120px;
  border-radius: 100% !important;
}

#nav-button.secondary-btn-s {
  margin-right: 0px !important;
}

#coffeeLogoMobile {
  display: none;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
}

.mobile-menu-icon img {
  width: 30px;
}

.mobile-menu {
  display: none;
}

.mobile-menu.dark {
  background-color: #000;
}

.mobile-menu li {
  margin-bottom: 20px;
  padding-top: 20px;
}

.language-selector {
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
}

.language-selector span {
  display: flex;
  /* This makes it a flex container */
  align-items: center;
  /* Centers items vertically */
  gap: 8px;
  /* Adds space between text and icon */
  font-weight: bold;
}

.language-options {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #F5EFEB;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  list-style: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

body.dark .language-options {
  background-color: #0D0D0D;
}


.language-options li {
  padding: 8px 12px;
  cursor: pointer;
  color: #01172F;
  border-radius: 12px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: 600;

}

body.dark .language-options li {
  color: #fff;
}

.language-options li:hover {
  background-color: #F2EAE5;
}

body.dark .language-options li:hover {
  background-color: #000;
}

#language-icon {
  width: 30px !important;
}

/* Loading Styles */
.loading {
  position: relative;
}

.loading .loading-item a {
  visibility: hidden;
}

/* New loading styles for mobile menu icon and mobile logo */
.loading .mobile-menu-icon,
.loading #coffeeLogoMobile {
  background: linear-gradient(90deg, #F9F5F2, #CCD1D5);
  background-size: 200% 200%;
  animation: loading 1.5s infinite;
}

.loading .mobile-menu-icon {
  width: 48px !important;
  height: 48px !important;
  border-radius: 4px !important;
}

.loading #coffeeLogoMobile {
  width: 140px !important;
  height: 140px !important;
  border-radius: 50% !important;
  /* Ensuring it's a perfect circle */
}

.loading .mobile-menu-icon img,
.loading #coffeeLogoMobile img {
  visibility: hidden;
}

@keyframes loading {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 1100px) {

  header {
    padding: 24px 0px 35px 0px;
    position: relative;
  }

  .desktop-menu {
    display: none;
  }

  #coffeeLogo {
    width: 140px !important;
    height: 140px !important;
  }

  #coffeeLogoMobile {
    display: inline-block !important;
    margin-top: 40px;
  }

  .mobile-menu-icon {
    display: block;
    width: 34px;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #F5EFEB;
    border-radius: 40px;
    position: absolute;
    width: 88%;
    top: 60px;
    padding: 64px 20px;
    z-index: 999;
  }
  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 9; /* Below the menu, above the page */
    transition: opacity 0.3s ease-in-out;
    backdrop-filter: blur(8px); /* Adjust the blur intensity */
    -webkit-backdrop-filter: blur(8px); /* For Safari support */
  }
  
  #close-icon-menu {
    width: 34px;
    left: 32px;
    top: 32px;
    position: absolute;
  }

  .mobile-menu li {
    margin: auto;
    padding-left: auto;
    list-style: none;
  }

  .mobile-menu li a {
    text-decoration: none;
    font-weight: bold;
    color: #01172F;
    text-transform: uppercase;
  }

  .mobile-menu.dark li a {
    color: #fff;
  }
}