/* src/components/Cart.css */
.cart {
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
}

.order-message {
  background-color: #C55D6C;
  color: #ffffff;
  padding: 16px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.order-message p {
  color: #ffffff;
}


.cart-wrapper {
  display: flex;
  flex-direction: column;
  width: 800px;
  padding-left: 0px;

}

.cart h2 {
  margin-bottom: 32px;
}

.cart.dark span {
  color: #fff;
}

.cart-items {
  width: 100%;;
}

.cart-item {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: row;
  width: 90%;
  background-color: #F9F5F2;
  border-radius: 12px;
  padding: 12px;
}

body.dark .cart-item {
  background-color: #0D0D0D;
}

.cart-item-header {
  margin-bottom: 16px !important;
  font-weight: bold;
  width: 96px;
}

.cart-item-product {
  width: 300px;
}

.cart-item-name {
  flex: 2;
  text-align: left;
  color: #001f2c;
  font-weight: 400;
}

.cart-item-quantity-selector {
  width: 96px;
}

.cart-item-quantity-selector button {
  background-color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
  color: #001f2c;
}

.cart-item-quantity-selector span {
  margin: 0 10px;
  font-size: 1rem;
}

.cart-item-price,
.cart-item-total {
  flex: 1;
  text-align: right;
  color: #001f2c;
  font-weight: 400;
}

.cart-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 12px;
  padding-left: 80px;
}

.cart-checkout h3 {
  padding-bottom: 16px;
}

#cart-secondary-btn {
  width: 100% !important;
}

#cart-primary-btn {
  width: 100% !important;
}
.cart-loading-wrapper {
  display: flex;
  flex-direction: row;
}
.cart-loading .cart-item-quantity-selector span {
  margin: 0px;
}


@media (max-width: 820px) {
  .cart {
    flex-direction: column;
    padding: 40px 0px 40px 0px;
  }

  .cart-wrapper {
    padding-left: 0px;
    width: 90vw;
  }

  .cart-item {
    display: flex;
    justify-content: space-around;
    align-items: start;
    margin-bottom: 15px;
    flex-direction: column;
  }

  .cart-item-header {
    margin-top: 16px;
  }

  .cart-checkout {
    margin-left: 0px;
    padding: 0px;
    align-items:flex-start;
  }

  .cart-checkout h3 {
    padding-top: 16px;
  }

  .cart-loading-wrapper {
    display: flex;
    flex-direction: column;
  }


}