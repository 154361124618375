.order-confirmation {
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 55px;
}

.order-confirmation-section {
    margin-top: 28px;
}

.order-confirmation-section h2 {
    margin-bottom: 28px;
}

.order-confirmation-section p {
    font-weight: bold;
}

.section-details {
    background-color: #F9F5F2;
    padding:12px;
    border-radius: 12px;
    display: inline-block;
}

body.dark .section-details {
    background-color: #0D0D0D;
}

#checkout-items .cart-item {
    width: 100% !important;
    align-items: flex-start !important;
}
