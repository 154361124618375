.testimonials {
  padding: 0px 50px 0px 50px;
  text-align: center;
  margin-top: 216px;
  margin-bottom: 208px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.testimonial-carousel {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  overflow: hidden;
}

.testimonials.dark .testimonial-carousel .testimonial {
  background-color: #000;
}

.arrow {
  background: none;
  border: none;
  cursor: pointer;
}

.arrow img {
  width: 40px;
  height: 40px;
}

.arrow.left {
  margin-right: -20px !important;
  z-index: 900;
}

.arrow.right {
  margin-left: -20px !important;
}


.testimonial {
  display: none;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  background-color: #f8f5f1;
  border-radius: 200px;
  padding: 20px;
  width: 90%;
  transition: opacity 1s ease-in-out;
}

.testimonial.active {
  display: flex;
}

.testimonial-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-right: 20px;
}

.testimonial-content {
  text-align: left;
  min-width: 80%;
  height: 120px;
  border-radius: 100%;
}

.testimonial-text {
  margin: 20px 0;
  width: 80% !important;
  padding-top: 24px;
}

.testimonial-author {
  font-weight: bold;
  margin: 0;
}

.testimonial-title {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 300;
  letter-spacing: 0px;
  margin: 0;
}

/* Media Query for responsiveness */
@media (max-width: 1000px) {
  .testimonials {
    padding: 20px;
  }

  .testimonial {
    flex-direction: column;
    align-items: center;
    min-width: 90%;
    padding: 20px;
    border-radius: 44px;
    min-height: 480px;
  }

  .testimonial-image {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .testimonial-content {
    text-align: center;
  }

  .testimonial-text {
    width: 100% !important;
  }
  

  .testimonial-carousel {
    flex-direction: column;
    overflow: visible;
  }

 .arrow {
  display: none;
 }
}
