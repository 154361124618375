.hero {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  padding: 0px 50px 0px 50px;
  margin-top: 74px;
  margin-bottom: 216px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.hero-text {
  max-width: 600px;
  margin-right: 140px;
  color: #01172F;
}

.hero.dark .hero-text {
  color: #fff;
}

.hero ul {
  list-style: none;
  text-transform: uppercase;
  margin-left: 0;
  padding-left: 0;
}

.hero ul li {
  margin-bottom: 12px;
}

.hero-image img {
  width: 460px;
  height: auto;
  margin-left: 0px;
}

.hero-text h1 {
  text-transform: uppercase;
  line-height: 1em;
}

.hero-text p {
  margin-bottom: 76px;
}

/* Loading Placeholder Styles for Hero Component */
.hero-loading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
  width: 100%;
}

.hero-loading-text {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 12px;
  margin-left: -40px;
}

.hero-loading-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-left: 100px;
  margin-right:-32px;
  margin-top: -20px;
}

.hero-loading-item {
  background: linear-gradient(90deg, #F9F5F2, #CCD1D5);
  background-size: 200% 200%;
  animation: hero-loading 1.5s infinite;
  border-radius: 8px;
}

@keyframes hero-loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 1200px) {
  .hero {
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding: 0px;
  }

  .hero-image {
    margin-top: 76px;
  }
}

/* Media query for screens smaller than 820px */
@media (max-width: 820px) {
  .hero {
    flex-direction: column;
    text-align: left;
    padding: 0px;
  }

  .hero-image img {
    width: 100%;
    max-width: 456px;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .hero-text {
    max-width: 100%;
    margin-right: 0px;
  }

  .hero-text h1 {
    font-size: 2.2em;
    word-wrap: break-word;
    word-break: normal;
  }

  .hero ul {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .hero ul li {
    margin-bottom: 0px;
  }

  .hero-text ul li a {
    margin-bottom: 24px;
  }

  #secondary-btn-hero {
    margin-bottom: 12px !important;
  }

  #hero-primary-btn {
    margin-top: 40px !important;
  }

  .hero-loading {
    flex-direction: column;
    text-align: left;
    left: 0px;
    width: 100%;
    margin: 0px;
    justify-content: left;
    align-items: flex-start;
    padding-left: 0px;
  }

  .hero-loading-text > :nth-child(1) {
    /* Styles for the first child */
    width: 98% !important;
  }
  
  .hero-loading-text > :nth-child(2) {
    /* Styles for the second child */
    width: 98% !important;
  }

  .hero-loading-text > :nth-child(3) {
    /* Styles for the third child */
    width: 50% !important;
    margin-top: 60px !important;
  }
  
  .hero-loading-text > :last-child {
    /* Styles for the last child */
    width: 60% !important;
    margin-top: 0px !important;
    margin-bottom: 40px;
  }

  .hero-loading-text {
    width: 100%;
    left: 0px;
    margin-left: 12px;

  }

  .hero-loading-image {
    margin-left: 0px;
    justify-content: left;
    width: 100%;
  }

  .hero-loading-image span .react-loading-skeleton {
    width: 240px !important;
    height: 240px !important;
    margin-top: 60px;
    left: 0px;
    padding-left: 0px;

  }
}
