.checkout {
    display:flex;
    flex-direction: row;
    padding-top: 4px;
    padding-left: 0px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.checkout h2 {
    padding-bottom: 28px;
}

#checkout-summary {
    margin-bottom: 13px;
    padding-left: 0px;
}

.checkout-section {
    margin-bottom: 28px;
}

#checkout-section-right {
    width: 25%;
    padding-left: 64px;
}

.failed-message {
    margin-top: 16px;
}

.checkout-shipping {
    margin-top: 28px;
}

.shipping-method {
    background-color: #F9F5F2;
    max-width: 720px;
    padding:12px;
    border-radius: 12px;
}

body.dark .shipping-method {
    background-color: #0D0D0D;
}

.payment-method {
    background-color: #F9F5F2;
    padding:12px;
    border-radius: 12px;
}

body.dark .payment-method {
    background-color: #0D0D0D;
}


.checkout-payment-method {
    margin-top: 28px;
}

.checkout-submit {
    margin-top: 76px;
    max-width: 400px;
}

.checkout-submit .react-loading-skeleton {
    margin-bottom: 12px;
    min-width: 300px;
}

.cart-item-quantity-selector .cart-item-header span.react-loading-skeleton {
    margin: 0px !important;
}

.checkout form {
    display: flex;
    flex-direction: column;
    max-width: 740px;
}

@media (max-width: 1200px) {
    .checkout {
        flex-direction: column;
        padding-left: 0px;
    }
    #checkout-section-right {
        width: 100%;
        padding-left: 0px;
    }
    
}