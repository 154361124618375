.footer {
  padding-left: 48px;
  padding-bottom: 60px;
  margin-top: 216px;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.footer-logo img {
  width: 8em;
  margin-bottom: 20px;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  margin: 10px;
  min-width: 150px;
}

.footer-column h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  font-size: 14px;
  margin-bottom: 12px;
  color: #01172F;
}

.footer.dark .footer-column ul li {
  color: #fff;
}

.footer-column ul li a{
  font-size: 14px;
  margin-bottom: 5px;
  color: #01172F;
  text-decoration: none;
}

.footer.dark .footer-column ul li a{
  color: #fff;
}




@media (max-width: 820px) {
  .footer {
    margin-left: 0px;
    padding-left: 0px;
  }
  .footer-columns {
    flex-direction: column;
    align-items: left;
  }
}