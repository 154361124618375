.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.feature-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 112px;
  width: 100%;
}

.feature-item.reverse {
  flex-direction: row;
}

.feature-item.reverse .feature-text {
  margin-left: 0;
  margin-right: 30px;
}

.feature-image img {
  max-width: 456px;
  height: auto;
  border-radius: 50%;
}

.feature-text {
  max-width: 600px;
  margin-left: 30px;
}

.feature-text h2 {
  margin-bottom: 20px;
}

.feature-text p {
  margin-bottom: 76px;
}

.loading-feature-image {
  margin-right: 96px;
}

.loading-feature-text {
  width: 100%;
  max-width: 564px;
}


/* Responsive styles */
@media (max-width: 1200px) {

  .feature {
    padding-left: 0px;
  }

  .loading-feature-image {
    margin: 0px;
    padding-bottom: 36px;
  }

  .feature-item {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
  }

  .feature-item.reverse {
    flex-direction: column-reverse;
  }

  .feature-text {
      margin-left: 0;
      margin-top: 20px;
      text-align: left;
  }

  .feature-text h1 {
      word-wrap: break-word;
      word-break: normal;
  }

  .feature-image skeleton {
      width: 200px;
  }

  .feature-image img {
      max-width: 456px;
      width: 100%;
      margin-bottom: 20px;
  }

  #featureImage2 {
      margin-top: 64px !important;
  }

  #feature-secondary-btn {
      margin-top: 32px !important;
      margin-bottom: 100px !important;
  }
}
