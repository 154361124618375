.product-details {
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.quantity-selector {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 76px;
    margin-bottom: 76px;
    margin-right: 40px;
}

.quantity-selector h3 {
    width: 60px;
    text-align: center;
}


.quantity-btn {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    padding: 0px;
    background-color: #fff;
    color: #000;
    border: none;
    font-size: 40px;
    cursor: pointer;
}

@media (max-width: 820px) {
    .product-details {
        margin-left: 0px;
        padding-left: 0px;
        flex-direction: column;

    }

    #product-details-image {
        margin-top: 96px;
    }
}