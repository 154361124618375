.breadcrumbs {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    margin-top: 79px;
}

.breadcrumbs ul {
    display: flex;
    list-style: none;
    padding: 0;
}

.breadcrumbs ul li {
    margin-right: 8px
}

.breadcrumbs ul li a {
    text-decoration: none;
}

.active-crumb p {
    font-weight: bold !important;
}

.breadcrumbs li:not(:last-child):not([id="skeleton"])::after {
    content: '/'; /* Separator */
    margin-left: 8px;
    color: #aaa; /* Optional styling for separator */
}

.breadcrumbs .react-loading-skeleton {
    margin-bottom: 6px !important; 
}

@media (max-width: 820px) {
    /*
    .breadcrumbs ul {
        flex-direction: column;

    }
    */
}