.newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 50px 0px 50px;
    margin-top: 104px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .newsletter-content {
    text-align: left;
  }

  .newsletter h1 {
    margin-bottom: 40px;
    color:#01172F;
  }
  
  .newsletter-form {
    display: flex;
    flex-direction: column;
  }
  
  .newsletter-form input {
    padding: 16px;
    margin-bottom: 20px;
    max-width: 500px;
  }

  .newsletter-form input:focus {
    outline: none;
  }

  #newsletter-btn {
    max-width: 160px !important;
    cursor: pointer;
    margin-top: 56px;
  }
  
  .success-message {
    font-weight: bold;
    margin-top: 20px;
  }
  

  
  /* Responsive styles */
  @media (max-width: 820px) {
    .newsletter {
      padding: 0px;
    }
  
  }
  